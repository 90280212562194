<template>
  <div class="import-container">
    <import-template
      :uploadUrl="submitUrl"
      :downloadUrl="downloadUrl"
      >
      <div slot="remark" class="remark">
        <p>1、模板中红色字体部分为必填项，灰色部分为非必填项；</p>
        <p>2、最大支持10MB的excel文件，超过请拆分导入。</p>
        <div>
          <label style="position: absolute;left: 90px;font-weight: 700;">字段说明：</label>
          <p v-for="(item, index) in remarkList" :key="index">
            {{ `${index + 1}、${item}`}}
          </p>
        </div>
      </div>
    </import-template>
  </div>
</template>
<script>
import { ImportTemplate } from 'components/bussiness/index'
import { uploadUrl, importExcelURL } from './api'
export default {
  components: {
    ImportTemplate
  },
  data () {
    return {
      uploadUrl,
      submitUrl: importExcelURL,
      downloadUrl: '/static/excel/台账模板.xls',
      remarkList: [
        '房产项目(智慧园区标准命名)：必填，必须是项目信息表中已有的项目名称',
        '设施设备一级分类名称：必填; 必须是设备分类表中已有的分类名称',
        '设施设备一级分类编号：必填; 必须是设备分类表中已有的分类名称对应的编号',
        '设施设备二级分类名称：必填; 必须是设备分类表中已有的分类名称',
        '设施设备二级分类编号：必填; 必须是设备分类表中已有的分类名称对应的编号',
        '设施设备三级分类名称：必填; 必须是设备分类表中已有的分类名称',
        '设施设备三级分类编号：必填; 必须是设备分类表中已有的分类名称对应的编号',
        '设施设备编号：必填; 限定30个字符',
        '设备名称：必填; 限30个字符',
        '经度：非必填',
        '纬度：非必填',
        '高度：非必填',
        '责任人：非必填; 限8个字符',
        '责任人手机号：非必填',
        '当前状态：必填，单选：在用、停用、已报废',
        '设备注册代码：非必填',
        '出厂编号：非必填',
        '设备型号：必填',
        '主要技术参数：非必填，限定200个字符',
        '梯速：非必填',
        '载重：非必填',
        '层（电梯的楼层数）：非必填',
        '站（电梯的停靠站数）：非必填',
        '门（电梯开门数）：非必填',
        '点位数（消防总点位数）：非必填',
        '系统数（一个项目消防分几个系统的）：非必填',
        '品牌：非必填',
        '制造厂商：非必填;',
        '产地（进口/国产）：非必填; 单选：国产、进口',
        '供应商：非必填',
        '出厂日期：非必填; 格式：YY-MM-DD',
        '安装日期：非必填; 格式：YY-MM-DD',
        '年检日期：非必填; 格式：YY-MM-DD',
        '所属组团：非必填; 项目中“苑”的信息',
        '安装位置：必填，限30个字符',
        '交付日期：非必填; 格式：YY-MM-DD',
        '始用日期：非必填; 格式：YY-MM-DD',
        '质保结束时间：非必填; 格式：YY-MM-DD',
        '额定电压：非必填',
        '额定电流：非必填',
        '频率：非必填',
        '总功率：非必填',
        '是否需要维保(按照管理模式生成)：必填，单选：是、否',
        '维保周期：需要维保时，必填，单选：周、半月、月、季度、半年、年',
        '首次维保日期：需要维保时，必填; 格式：YY-MM-DD',
        '维保单位：需要维保时，必填',
        '维保单位分类：非必填，单选：原厂维保、外委维保、集团维保',
        '外包类型：非必填，（单选：质保期内、无外包、甲方外包、清包、中包、大包）',
        '维保合同开始时间：非必填; 格式：YY-MM-DD',
        '维保合同结束时间：非必填; 格式：YY-MM-DD',
        '是否需要巡检：必填，单选：是、否',
        '巡检周期：必填，单选：日、周、半月、月、季度、半年',
        '巡检频次：整数，1-99',
        '巡检间隔周期：日、小时',
        '间隔时间：整数，两次巡检间隔最小时间',
        '首次巡检时间：必填; 格式：YY-MM-DD',
        '是否抄表：必填，单选：是、否',
        '备注：非必填; 限定10000个字符',
        '巡检/维保方式：必填，单选：二维码、蓝牙',
        '蓝牙编号：巡检/维保方式为蓝牙时，必填'

      ]
    }
  },
  created () {
    this.$setBreadcrumb('导入')
  }
}
</script>
<style lang="scss" scoped>
  .remark{
    line-height: 24px;
  }
</style>
