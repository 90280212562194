var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "import-container" },
    [
      _c(
        "import-template",
        { attrs: { uploadUrl: _vm.submitUrl, downloadUrl: _vm.downloadUrl } },
        [
          _c(
            "div",
            {
              staticClass: "remark",
              attrs: { slot: "remark" },
              slot: "remark",
            },
            [
              _c("p", [
                _vm._v("1、模板中红色字体部分为必填项，灰色部分为非必填项；"),
              ]),
              _c("p", [_vm._v("2、最大支持10MB的excel文件，超过请拆分导入。")]),
              _c(
                "div",
                [
                  _c(
                    "label",
                    {
                      staticStyle: {
                        position: "absolute",
                        left: "90px",
                        "font-weight": "700",
                      },
                    },
                    [_vm._v("字段说明：")]
                  ),
                  _vm._l(_vm.remarkList, function (item, index) {
                    return _c("p", { key: index }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(`${index + 1}、${item}`) +
                          "\n        "
                      ),
                    ])
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }